import * as React from "react"
import {graphql, Link} from "gatsby"
import Head from "../components/Head";
import {GatsbyImage} from "gatsby-plugin-image";
import Layout from "../components/Layout";

export const query = graphql`
    {
  allSanityStartseite {
    edges {
      node {
      image{
        asset{ gatsbyImageData}
      }
          }
      }}
      allSanitySettings{
        edges{ node { fblink instalink linkedinlink}}
      }}`;

const NotFoundPage = ({data}) => {
  const page = data.allSanityStartseite.edges[0].node;

  return (
    <Layout landing={true} settings={data.allSanitySettings.edges[0].node}>
      <Head title="404"/>
      <div className="landing__section error">
        <GatsbyImage image={page.image.asset.gatsbyImageData} alt="Page not found"></GatsbyImage>
        <h1 className="color-orange uppercase">404</h1>
        <p className="large color-black">Seite konnte nicht gefunden werden</p>
        <div className="landing__bottombar">
          <Link to={'/'} className="btn outline white landingbtn smalltext bold">Startseite</Link>
          <Link to={'/kontakt'} className="btn white outline landingbtn smalltext bold">
            Kontakt</Link>
        </div>
      </div>
    </Layout>

  )
}

export default NotFoundPage
